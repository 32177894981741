<!-- <p>ed-about works!</p> -->
<!-- <app-navbar-style-four></app-navbar-style-four> -->
<app-navbar-style-three></app-navbar-style-three>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>


<!--  -->
<div class="service-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
           
            <h2> <span class="sp-after">About Us</span>
                </h2>
            <p>Eduwego is a complete Student
                Relations Management System (SRM)
                which helps educational institutions to
                automate journey of a Student from
                Admission till job placement.
                
            </p>
            <span class="sp-after">Eduwego is a fully loaded Paperless Admission Management Software at
                Unbeatable Price & fully Customisable Plans to grow your number of
                admissions at a very affordable cost for Schools , Colleges , Universities ,
                Education Consultants , EdTechs and online Education Portals.
            </span>
        </div>
    </div>
</div>

<div class="service-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
         
            <h2>WHY EDUWEGO</h2>
            <p>Its 100 % Safe & Secure SaaS to save you to lose any opportunities to
                grow your admissions. We will be giving end to end solution to grow
                the numbers of admissions by 60 X. This is fully designed inculcating
                feedbacks & suggestions by the top Admissions Experts. This
                empowers the Admission Team not to lose even a single lead from
                different channels. Application service automation is an integral part
                of admission management where a service is deployed to centrally
                and most accurately control applications and any changes in them. It
                covers the entire lifecycle of an online application such as operation
                of an application, maintenance, version and copyright control, and
                throughout upgrades.
            </p>
       
        </div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <!-- <span class="sp-before sp-after">About Us</span> -->
                        <h2>Grow Your Enrollments With Eduwego</h2>
                    </div>
                    <!-- <h3>No matter where our clients are based and what language they speak, “zabardast” (Hindi for “resounding”) is something that they identify our product, people, and processes with.</h3>
                    <p> The story? A lot of partner educational organizations who we’ve been working with since the early days of our founding gave us this identity we treasure the most. It is what breaks the ice whenever someone from any part of the world reaches out to us for our solutions.</p> -->
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Auto Point of Admission Letters</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>360° Lead Management</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Communication Channel All</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Training to your Staff By Sales </p>
                            </li>
                        </ul>
                    </div>
                    <div class="about-video">
                        <a (click)="link()" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Eduwego</span></a>
                        <!-- <button class="video-btn popup-btn"><i class='flaticon-forward'></i>Play</button> -->
                        <!-- <video src="../../../../assets/img/Eduwego - Admission Management Software.mp4"></video> -->
                        <!-- <a (click)="showVideo()" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Eduwego</span></a> -->
                 

                        <!-- <video *ngIf="videoVisible" width="500" height="240" controls>
                        <source src="../../../../assets/img/Eduwego - Admission Management Software.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                        </video> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>7 Years</h3>
                    <p>OF SUCCESS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>70+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>250+</h3>
                    <p>COUNSELLOR TRAINED</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Journey’s Path</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Sold Prototype</h3>
                    <!-- <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p> -->
                    <ul>
                        <li><i class='bx bx-check'></i> 2017-18</li>
                        <li><i class='bx bx-check'></i> 2018-19</li>
                        <li><i class='bx bx-check'></i> 2019-20</li>
                        <li><i class='bx bx-check'></i> 2021-22</li>
                        <li><i class='bx bx-check'></i> 2022-23</li>
                        <li><i class='bx bx-check'></i> 2023-24</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Started In Small Town</h3>
                    <!-- <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p> -->
                    <ul>
                        <li><i class='bx bx-check'></i> 3 Team Member</li>
                        <li><i class='bx bx-check'></i> 150 sq. Office</li>
                        <!-- <li><i class='bx bx-check'></i> We Turn Heads</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Sold in Metro</h3>
                    <!-- <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p> -->
                    <ul>
                        <li><i class='bx bx-check'></i> Ranchi</li>
                        <li><i class='bx bx-check'></i> Pune</li>
                        <li><i class='bx bx-check'></i> Bangalore</li>
                        <li><i class='bx bx-check'></i> South Africa</li>
                        <li><i class='bx bx-check'></i> Hydrabad</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">360° Support To Educational Institute</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Customer relationship management<span>1</span></h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                        <li class="text-end">
                            <h3>Small Campaigns <span>2</span></h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                        <li class="text-end">
                            <h3>SMS Campaigns <span>3</span></h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                        <li class="text-end">
                            <h3>Regional Offices <span>4</span></h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>5</span>Whatsapp Campaigns</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                        <li>
                            <h3><span>6</span>Education COUNSELOR/BPO</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                        <li>
                            <h3><span>7</span>Live Application Form</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                        <li>
                            <h3> <span>8</span>Online Payment Gateway</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="case-study-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies1.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Detection Project</a></h3>
                        <span>System Project</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies2.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Customer Segmentation</a></h3>
                        <span>Machine Learning</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies3.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Data Analysis</a></h3>
                        <span>Data Analysis</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies4.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Web Development</a></h3>
                        <span>Programming</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies5.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Artificial Intelligence</a></h3>
                        <span>Machine Learning</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies6.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Data Scientist</a></h3>
                        <span>Data Science</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/case-study" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/case-study" class="page-numbers">2</a>
                    <a routerLink="/case-study" class="page-numbers">3</a>
                    <a routerLink="/case-study" class="page-numbers">4</a>
                    <a routerLink="/case-study" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->



<!-- <div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Frame the Problem <span>1</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Collect the Raw Data <span>2</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Process the Data <span>3</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Explore the Data</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>5</span>Perform Analysis</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>6</span>Communicate Results</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2>The Facts About Our Company</h2>
        </div>

        <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2010</h3>
                        <span>February 20th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2012</h3>
                        <span>January 14th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2016</h3>
                        <span>March 25th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>December 10th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>February 01th</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Great Success of the Year</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip comodo consequa dolor consectetur</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation </p>
                    <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div> -->

<!-- <div class="testimonial-area testimonial-area-mb ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Words From Customers</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>David McLean</h3>
                                <span>CEO & Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Jermin Jekson</h3>
                                <span>Marketing Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Julfiker Jeain</h3>
                                <span>CEO At Ostino</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->
<app-ed-tech-banner></app-ed-tech-banner>
<app-footer-style-two></app-footer-style-two>