import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extra-edge',
  templateUrl: './extra-edge.component.html',
  styleUrls: ['./extra-edge.component.scss']
})
export class ExtraEdgeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
