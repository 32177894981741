
<app-navbar-style-three></app-navbar-style-three>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center aat">
            <h3>Pricing Plan</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pricing Plan</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-0 pb-0">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">Pricing Plan</span>
            <h2 class="h2-color">Price & Plans <b>Packages</b></h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">Prime
                            </h3>
                            <div class="price-rate">
                                <h2 class="color-1">₹ 30,000 </h2>
                                <span class="color-1">Per Academic Year</span>
                            </div>
                            <ul>
                                <li> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i> Manage Leads upto <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>Web version</b> Business Whatsapp
                                </li>
                                <!-- <li><i class='bx bx-check'></i>IVR</li> -->
                                <!-- <li><i class='bx bx-check'></i>Website</li> -->
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <!-- <li><i class='bx bx-check'></i>Data Migrates</li> -->
                                <!-- <li><i class='bx bx-check'></i>Personal Sender Id</li> -->
                                <!-- <li><i class='bx bx-check'></i>SMS</li> -->
                                <li><i class='bx bx-check'></i> <b>10</b> No. of Users
                                </li>
                                <!-- <li><i class='bx bx-check'></i>Android mobile app</li> -->
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <li><del>IVR</del></li>
                                <li><del>Website</del></li>
                                <li><del>Data Migrates</del></li>
                                <li><del>Personal Sender Id</del></li>
                                <li><del>Android Mobile App</del></li>
                                
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a href="https://pages.razorpay.com/pl_EZeEqNXT0FenoF/view" target="_blank" class="purchase-btn button-bg1">Subscribe NOW</a>
                            <b>
                                <p>₹2083 per month</p>
                            </b>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">Prime Plus</h3>
                            <div class="price-rate">
                                <h2 class="color-2">₹80,000</h2>
                                <span class="color-2">Per Academic Year</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i> Manage Leads upto <b>1000</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>1000</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>2500</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>API version</b> Business Whatsapp
                                </li>
                                <li><i class='bx bx-check'></i>IVR</li>
                                <li><i class='bx bx-check'></i>Website</li>
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <li><i class='bx bx-check'></i>Data Migrates</li>
                                <li><i class='bx bx-check'></i>Personal Sender Id</li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i> <b>20</b> No. of Users
                                </li>
                                <li><i class='bx bx-check'></i>Android mobile app</li>
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <!-- <li><i class='bx bx-check'></i>SMS</li> -->
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a href="https://pages.razorpay.com/pl_EZdeRDinEpFbbO/view" target="_blank" class="purchase-btn button-bg2">Subscribe Now</a>
                            <b>
                                <p>₹5000 per month</p>
                            </b>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">Prime Pro</h3>
                            <div class="price-rate">
                                <h2 class="color-3">₹1,20,000</h2>
                                <span class="color-3">Per Academic Year</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i>  Leads up to <b>Unlimited</b>
                                    
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>Unlimited</b>
                                    
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>Unlimited</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>API version</b> Business Whatsapp
                                </li>
                                <li><i class='bx bx-check'></i>IVR</li>
                                <li><i class='bx bx-check'></i>Website</li>
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <li><i class='bx bx-check'></i>Data Migrates</li>
                                <li><i class='bx bx-check'></i>Personal Sender Id</li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i> <b>20</b> No. of Users
                                </li>
                                <li><i class='bx bx-check'></i>Android mobile app</li>
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a href="https://pages.razorpay.com/pl_EZefxZp7tG41xL/view" target="_blank" class="purchase-btn button-bg3">Subscribe Now</a>
                            <b>
                                <p>₹8,333.3 per month</p>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <p id="z-taxplus" style="display: block;">Local taxes (VAT, GST, etc.) will be charged in addition to the prices mentioned.</p>
            <p>* Inclusive of archival and retention storage. <a href="/pricing">Know more</a>.</p>
        </div>
    </div>





    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-0 pb-0">
  <div class="container-fluid">
      <div class="section-title text-center">
          <!-- <span class="sp-after">Pricing Plan</span>
          <h2 class="h2-color">Price & Plans <b>Packages</b></h2> -->
          <h2> Free Forever Plan for College & Institute</h2>
          <a class="trynow" href="https://wa.aisensy.com/mBmyV5" target="_blank">Whatsaap <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg></a>
      </div>

      <div class="price-width">
          <div class="row">
              <div class="col-lg-4 col-sm-6">
                  <div class="pricing-card">
                      <div class="pricing-card-into color-bg4">
                        <h4>Forever Free Plan</h4>
                          <!-- <i class="flaticon-banner pricing-icon color-1"></i>
                          <h3 class="color-1">Prime
                          </h3>
                          <div class="price-rate">
                              <h2 class="color-1">₹ 25,000 </h2>
                              <span class="color-1">Per Academic Year</span>
                          </div> -->
                          <ul>
                            <li> No. of Users -
                                <b>1</b>

                            </li>
                              <li> Applications up to - <b>50</b>

                              </li>
                              <li> Leads up to -<b>250</b>

                              </li>
                              <li> Students up to - <b>50</b>

                              </li>
                           
                          </ul>
                          <a href="https://apps.eduwego.in/signup" class="purchase-btn button-bg1">Sign Up NOW</a>
                          <!-- <b>
                              <p>₹2083 per month</p>
                          </b> -->
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                  <div class="pricing-card">
                      <div class="pricing-card-into color-bg2">
                        <h4>Contact Sales</h4>
                          <!-- <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                          <h3 class="color-2">Prime Plus</h3>
                          <div class="price-rate">
                              <h2 class="color-2">₹60,000</h2>
                              <span class="color-2">Per Academic Year</span>
                          </div> -->
                          <ul>
                              <li> Get Custom plans for bulk user and enterprises
                              </li>
                      
              
                          </ul>
                          <a routerLink="/contact" class="purchase-btn button-bg2">Contact Us</a>
                          <!-- <b>
                              <p>₹5000 per month</p>
                          </b> -->
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                  <div class="pricing-card">
                      <div class="pricing-card-into color-bg6">
                          <!-- <i class="flaticon-processing pricing-icon color-3"></i> -->
                          <!-- <h3>Flexible Pricing</h3> -->
                          <h4>Customised pricing</h4>
                          <!-- <div class="price-rate">
                              <h2 class="color-3">₹1,00,000</h2>
                              <span class="color-3">Per Academic Year</span>
                          </div> -->
                          <ul>
                              <li> Mix and Match different plans for different user in your organization
                              </li>
                      
                          </ul>
                          <a routerLink="/contact" class="purchase-btn button-bg3">Contact Sales</a>
                          <!-- <b>
                              <p>₹8,333.3 per month</p>
                          </b> -->
                      </div>
                  </div>
              </div>
              <div class="section-title text-center">
                <span class="sp-after">Single User + Admin</span>
                <h2 class="h2-color">One Time Setup Fee ₹7999 + GST</h2>
                <!-- <h2>15-day free trial for Mail Premium plan. No credit card required.</h2>
                <a class="trynow" href="#">Try Now</a> -->
            </div>
          </div>
      </div>
  </div>






  <!-- <div class="pricing-shape">
      <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
      <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
      <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
      <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
      <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
      <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
  </div> -->
</div>




<app-ed-tech-banner></app-ed-tech-banner>

<app-footer-style-two></app-footer-style-two>