<!-- <p>detail-contact works!</p> -->
<!-- <app-navbar-style-four></app-navbar-style-four> -->
<!-- <app-navbar-style-two></app-navbar-style-two> -->
<app-navbar-style-three></app-navbar-style-three>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="contact-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3> Bangalore Office Location</h3>
                    <p>#5 , Electronic City Phase 1 , Neeladari Road , Bangalore , 560100
                        </p>
                    <p>
                        <b>Contact Person :</b> Mr Vijay Singh</p>
                    <a target="_blank" href="#" class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Sales Enquiries
                    </h3>
                    <p>Email  sales@eduwego.in

                    </p>
                    <p>Mobile: +91 9031022881</p>
                    <br>
                    <a href="mailto:sales@eduwego.in" class="contact-card-btn">Contact Us<i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Support Enquiries </h3>
                    <p>Email  support@eduwego.in
                    </p>
                    <p>Mobile: +91 9031022882</p>
                    <br>
                    <a href="mailto:support@eduwego.in" class="contact-card-btn">Contact Us<i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>

 
    </div>
</div>
<!-- <div class="contact-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Get in Touch</h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3> Kolkata Office Location</h3>
                    <p>Office Number 419A , PS Cube , Rajarhat Main Road , Kolkata

                        </p>
                    <p>
                        <b>Contact Person :</b> Mr Vijay Singh</p>
                    <a target="_blank" href="#" class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>Email   sales@eduwego.in
                    </p>
                    <p>Mobile: +91 8210920357</p>
                    <a routerLink="/" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 09:00 - 20:00</p>
                    <p>Sunday & Saturday: 10:30 - 22:00</p>
                    <a href="mailto:info@ribo.com" class="contact-card-btn">Support <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>


        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3> Agartala Office Location</h3>
                    <p>Champamura Bazar Rd, Gakulnagar, Tripura 799130

                        </p>
                    <a target="_blank" href="#" class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>Email  support@eduwego.in
                    </p>
                    <p>Mobile: +91 8210920357</p>
                    <a routerLink="/" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 09:00 - 20:00</p>
                    <p>Sunday & Saturday: 10:30 - 22:00</p>
                    <a href="mailto:info@ribo.com" class="contact-card-btn">Support <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="contact-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Get in Touch</h2>
        </div>
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3> Agartala Office Location</h3>
                    <p>Champamura Bazar Rd, Gakulnagar, Tripura 799130

                        </p>
                    <a target="_blank" href="#" class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>Email  support@eduwego.in
                    </p>
                    <p>Mobile: +91 8210920357</p>
                    <a routerLink="/" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 09:00 - 20:00</p>
                    <p>Sunday & Saturday: 10:30 - 22:00</p>
                    <a href="mailto:info@ribo.com" class="contact-card-btn">Support <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="courses-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span class="sp-after">Our Courses</span> -->
            <h2 class="h2-color2">Regional Office
            </h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p> -->
        </div>

        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="../../../../assets/img/logo/kol.png" alt="Images"></a>
                    </div>
                    <h3><a routerLink="/">Kolkata, West Bengal
                    </a></h3>
                    <p>Office Number 419A , PS Cube ,  <br>Rajarhat Main Road,Kolkata <br>
                        <b>Contact Person :</b> Meghna Adarsh <br>
                        <b>Email :</b> meghna@eduwego.in
                        </p>
                    <ul>
                        <!-- <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 8 Weeks Long</li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="../../../../assets/img/logo/019-temple-removebg-preview.png" alt="Images"></a>
                        
                    </div>
                
                    <h3><a routerLink="/"> Agartala, Tripura</a></h3>
                    <p>Champamura Bazar Rd, Gakulnagar, <br> Tripura 799130 <br>
                        <b>Contact Person :</b> Rajesh Debnath
                        <br>
                        <b>Email :</b> rajesh@eduwego.in
                        </p>
                    <ul>
                        <!-- <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 9 Weeks Long</li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="../../../../assets/img/logo/019-temple-removebg-preview.png" alt="Images"></a>
                    </div>
                    <h3><a routerLink="/">Ranchi , Jharkhand
                    </a></h3>
                    <p>Off Narsaria Complex , Lalpur Chowk , Ranchi , Jharkhand 834008
                        <br>
                        <b>Contact Person :</b> Afzal
                        <br>
                        <b>Email :</b> afzal@eduwego.in
                        </p>
                    <ul>
                        <!-- <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 8 Weeks Long</li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="../../../../assets/img/logo/012-india_gate-removebg-preview.png" alt="Images"></a>
                    </div>
                    <h3><a routerLink="/">Delhi NCR
                    </a></h3>
                    <p>A25, Main Road, A Block, Sector 59, Noida, Uttar Pradesh 201301 <br>
                        Contact Persion : Mr Binit Kumar <br>
                        <b>Email :</b> binit@eduwego.in
                        </p>
                        
                    <ul>

                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img3.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">$78</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor3.png" alt="Images">
                        <h3 class="name"><a routerLink="/">Juhan Luis</a></h3>
                    </div>
                    <h3><a routerLink="/">Data Science Inference and Modeling</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 5 Weeks Long</li>
                    </ul>
                </div>
            </div> -->

            <!-- <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img4.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">Free</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor4.png" alt="Images">
                        <h3 class="name"><a routerLink="/"> Miron Don</a></h3>
                    </div>
                    <h3><a routerLink="/">Introduction to Linear Data Visualization</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 4 Weeks Long</li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</div>


<!-- <div class="contact-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-img">
                    <img src="assets/img/contact-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="section-title">
                            <span class="sp-before sp-after">Contact</span>
                            <h2>Contact With Us</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->




<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.080097897188!2d77.64497757386536!3d12.838100517793523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6b79311a8f27%3A0xf143fbd0990d9e99!2sAdmission%20%26%20Student%20Management%20Software!5e0!3m2!1sen!2sin!4v1694086037967!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>