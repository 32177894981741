<!-- <p>demo-page works!</p> -->
<app-navbar-style-three></app-navbar-style-three>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center aat">
            <h3>Demo</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Demo</li>
            </ul>
        </div>
    </div>
<div class="position-relative overflow-hidden py-4 get-demo">
    <div class="stepJourney">&nbsp;</div>
    <div class="container">
        <div class="row order-reverse">
            <div class="col-md-5 col-sm-5 my-3 order-reverse-2">
                <div class="get-demo__wrapper-right">
                    <div class="get-demo__form-mobile">
                        <h1 class="get-demo__title" style="padding-top:0px;">Get a demo of Meritto's&nbsp;purpose-built
                            products</h1>
                        <p class="mb-0">You’re well on your way to make your Sales, Marketing, Admission, Finance, and
                            Operation Teams more productive with our powerful solutions. They’re super easy to use and
                            are built to fit and adapt to the way your teams work.</p>
                    </div><!-- Calendly inline widget begin -->
                    <div class="calendly-inline-widget" data-url="https://calendly.com/d/yhc-6hr-skq?hide_gdpr_banner=1"
                        style="position: relative;min-width:320px;height:560px;" data-processed="true">&nbsp;<div
                            class="calendly-spinner">
                            <div class="calendly-bounce1"></div>
                            <div class="calendly-bounce2"></div>
                            <div class="calendly-bounce3"></div>
                        </div><iframe
                            src="https://calendly.com/d/yhc-6hr-skq?embed_domain=pages.meritto.com&amp;embed_type=Inline&amp;hide_gdpr_banner=1"
                            width="100%" height="100%" frameborder="0"
                            title="Select a Date &amp; Time - Calendly"></iframe></div>
                </div>
            </div>
            <div class="col-md-7 col-sm-7 my-3 order-reverse-1">
                <div class="get-demo__form-mobile-none">
                    <h1 class="get-demo__title">Get a demo of Meritto's&nbsp;purpose-built products</h1>
                    <p class="mb-4 pb-2">You’re well on your way to make your Sales, Marketing, Admission, Finance, and
                        Operation Teams more productive with our powerful solutions. They’re super easy to use and are
                        built to fit and adapt to the way your teams work!</p>
                </div>
                <div class="get-demo__features">
                    <h3 class="get-demo__features-title mb-2">More reasons to choose us</h3>
                    <div class="get-demo__features-wrapper">
                        <div class="get-demo__features-item">
                            <ul class="pl-1">
                                <li class="get-demo__features-item--feature">✅ Purpose-built to keep you enrolling</li>
                                <li class="get-demo__features-item--feature">✅ Scalability at the forefront</li>
                                <li class="get-demo__features-item--feature">✅ Dedicated training and migration support
                                </li>
                                <li class="get-demo__features-item--feature">✅ A vibrant and collaborative community
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="stepJourney pos-b">&nbsp;</div>
</div>
<app-footer-style-two></app-footer-style-two>