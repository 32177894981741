<footer class="footer-area footer-bg pt-100 pb-70">
    <div class="container">
        <div class="footer-top">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="footer-img">
                        <img src="assets/img/logo/logo1.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="footer-social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-midal pt-45 pb-70">
            <div class="row">
                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Services</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">International SEO</a></li>
                            <li><a routerLink="/">Enterprise SEO</a></li>
                            <li><a routerLink="/">SEO for Small Business</a></li>
                            <li><a routerLink="/">SEO for Local Services</a></li>
                            <li><a routerLink="/">National SEO</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-7">
                    <div class="footer-widget">
                        <h3>Company</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">About the Company</a></li>
                            <li><a routerLink="/">For Customers</a></li>
                            <li><a routerLink="/">SEO Blog & News</a></li>
                            <li><a routerLink="/">Careers & Reviews</a></li>
                            <li><a routerLink="/">Sitemap</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget pl-5">
                        <h3>Learning</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">About the Company</a></li>
                            <li><a routerLink="/">International SEO</a></li>
                            <li><a routerLink="/">National SEO</a></li>
                            <li><a routerLink="/">Careers & Reviews</a></li>
                            <li><a routerLink="/">Sitemap</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <h3>Newsletter</h3>
                        <p>To get the latest news and latest up- dates from us</p>
                        <div class="newsletter-area">
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Your Email*" name="EMAIL" required autocomplete="off">
                                <button class="default-btn" type="submit">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="copy-right-text text-center">
                <p>© Ribo is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
            </div>
        </div>
    </div>
</footer>