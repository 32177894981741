<!-- <p>mainpage works!</p> -->
<app-navbar-style-three></app-navbar-style-three>
<!-- 1 -->
<div class="banner-area-three">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="banner-content-three">
                        <span>Grow Your Business </span>
                        <h1>
                            Lead management + Fee management + Admissions Management on single dashboard</h1>
                        <p>Eduwego is a fully loaded paperless admission management software to grow your admissions rapidly at a very affordable pricing.</p>
                        <div class="banner-three-btn">
                            <a href="https://apps.eduwego.in/signup" target="_blank" class="explore-btn">Book Free Demo<i class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/@eduwego" target="_blank" class="play-two-btn ">Claim Free Account <i
                                    class='flaticon-forward'></i></a>
                        </div>
                        <b>
                            <p>Book A 30 min Demo or Call us +91-8062177352
                            </p>
                        </b>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-img-3">
        <img src="../../../assets/img/3684518-removebg-preview.png" alt="Images">
    </div>

    <div class="banner-three-shape">
        <div class="shape1"><img src="assets/img/shape/shape16.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape15.png" alt="Images"></div>
    </div>
</div>
<br>
<!-- 2 -->

<!-- 3 -->
<div class="service-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span >Our Services</span>
            <h2>Grow Your Business With Eduwego</h2>
            <p>We have over 12 years experience in Admission Management
            </p>
            <span>
                We are committed to provide you with best cloud Admissions manager. From Leads to Students Eduwego will take care of everything including managing your leads & scale up your admissions up to 10X
            </span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-item-two">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon10.png" alt="assets"></a>
                    <h3><a routerLink="/services-details">Effortless Application Management </a></h3>
                    <p>Easily manage and track student applications online.
                        Streamline the admission process from application submission to acceptance.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-item-two">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon11.png" alt="assets"></a>
                    <h3><a routerLink="/services-details">Customizable Workflows </a></h3>
                    <p>Tailor admission workflows to fit your college's unique needs.
                        Create automated processes for greater efficiency.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="services-item-two">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon12.png" alt="assets"></a>
                    <h3><a routerLink="/services-details"> Real-time Analytics </a></h3>
                    <p>Gain insights into applicant demographics, trends, and admission funnel performance.
                        Make data-driven decisions to optimize your admissions strategy.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 4 -->
<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="../.../../../../assets/img/logo/ADMISSION TRACKING.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span>OUR VISION</span>
                            <h2>Admission tracking platform for every college.</h2>
                        </div>
                        <h3>We focus on consistently Evolving to ensure we're increasing number of enrolment of the organisation.
                        </h3>
                        <p>Our mission is to simplify and enhance the college admissions experience for both colleges
                            and applicants. We are dedicated to providing innovative solutions that empower colleges to
                            make data-driven decisions and streamline their admissions processes.</p>
                        <div class="about-btn-two">
                            <a href="https://apps.eduwego.in/signup" class="about-more-btn">Sign Up <i class='bx bx-plus'></i></a>
                            <a href="https://wa.aisensy.com/mBmyV5" class="contact-us-btn">Whatsapp </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 5 -->
<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">


                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="">OUR MISSION</span>
                            <h2>Simple
                                Affordable
                                Reliable</h2>
                        </div>
                        <h3>We have simple yet sufficient features to optimize the performance & results, easy to learn & use, super cost effective leveraging higher customer satisfaction & an amazing after enablement support team ready to minimize your issues in no time!</h3>
                        <p>Feel free to adapt and customize this content to suit your specific platform and its
                            features. Additionally, ensure that your website design is user-friendly and visually
                            appealing to engage your target audience effectively.</p>
                        <div class="about-btn-two">
                            <a routerLink="/about" class="about-more-btn">Sign Up <i class='bx bx-plus'></i></a>
                            <a href="https://wa.aisensy.com/mBmyV5" class="contact-us-btn">Whatsapp </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="../.../../../../assets/img/logo/AFFORDABLE RELIABLE.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 6 -->
<div class="schedule-area">
    <div class="container-fluid">
        <div class="schedule-width ptb-100">
            <div class="schedule-content aae1">
                <h2>Interested to see how helpful are we</h2>
                <a href="https://apps.eduwego.in/signup" target="_blank" class="default-btn-two">Request a demo here <i class='bx bx-plus'></i></a>
                <div class="schedule-content-shape">
                    <div class="shape1"><img src="assets/img/shape/shape15.png" alt="Images"></div>
                    <div class="shape2"><img src="assets/img/shape/shape16.png" alt="Images"></div>
                </div>
            </div>
            <div class="schedule-shape">
                <img src="assets/img/shape/schedule-shape.png" alt="Images">
            </div>
        </div>
    </div>
</div>
<!-- 7 -->
<div class="serve-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="serve-content">
                    <div class="section-title">
                        <span class="">Why are we so loved and appreciated?</span>
                        <h2 class="h2-color2">Growth of 10x in Admissions</h2>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna tetur adipisicing saliqua.</p> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Auto Email to Leads</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Auto SMS to Leads</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Admission Offer Letter Generation</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Followups Whatsaap Templets</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Various Lead Status</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>06</span>
                                <p>Application fee Payment</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>07</span>
                                <p>Separate Leads Page</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>08</span>
                                <p>One Click Call to Leads</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="serve-list">
                                <i class='bx bx-check'></i>
                                <span>09</span>
                                <p>Video Counselling to Leads</p>
                            </div>
                        </div>
                    </div>
                    <p>
                        When choosing an enrollment CRM, consider the specific needs of your institution and team.
                        Ensure that the CRM you select aligns with your enrollment processes and can grow with your
                        institution. Additionally, test out free trials or demos to see which CRM platform is the best
                        fit for your needs in terms of simplicity, affordability, and reliability
                    </p>
                    <a href="https://wa.aisensy.com/mBmyV5" class="default-btn-two">Get the demo <i
                            class='bx bx-plus'></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="serve-img">
                    <img src="../../../../assets/img/10-X-in-Growth.png" alt="Images">
                </div>
            </div>
            <div class="courses-area pb-70">
                <div class="container">
                    <div class="row pt-45">
                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="images">
                                    <a routerLink="/"><img src="../../../assets/img/logo/icons8-dashboard-65.png" alt="Images"></a>
                                </div>
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-c172516 elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="c172516" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="fas fa-user"></i> </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Multi login Dashboards </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Your Data is safe , secured &amp; confidential , Multi <br> login
                                                        Dashboards enables you to share the <br> data needed to concern
                                                        team. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="images">
                                    <a routerLink="/"><img src="../../../assets/img/logo/icons8-letter-65.png" alt="Images"></a>
                                </div>
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-7248787 elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="7248787" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="far fa-edit"></i> </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Auto Generation of Admission  <br>Letters </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Generate All types of Letters like Admission Letter ,
                                                        Bonafide Letter etc. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="images">
                                    <a routerLink="/"><img src="../../../assets/img/logo/icons8-progress-65.png" alt="Images"></a>
                                </div>
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-b0cfd79 elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="b0cfd79" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="fas fa-chart-pie"></i> </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Tracking &amp; progress </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Get Tracking of All Leads &amp; Enrolments to verify the
                                                        strength of Admissions . </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="images">
                                    <a routerLink="/"><img src="../../../assets/img/logo/icons8-facebook-65.png" alt="Images"></a>
                                </div>
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-8431b6f elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="8431b6f" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="fab fa-facebook-square"></i>
                                                    </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Facebook Ads Integration </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Get Data from your facebook ads directly from your Meta
                                                        Business Account or Facebook page. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="images">
                                    <a routerLink="/"><img src="../../../assets/img/logo/icons8-google-adwords-65.png" alt="Images"></a>
                                </div>
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-448df8b elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="448df8b" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="fab fa-google"></i> </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Google Ads Integration </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Get Data of your Google ads in your dashboard from your Google
                                                        Ads Account. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="images">
                                    <a routerLink="/"><img src="../../../assets/img/logo/Call IVR.png" alt="Images"></a>
                                </div>
                                <div class="elementor-icon-box-wrapper">
                                    <div class="elementor-icon-box-icon">
                                        <span class="elementor-icon elementor-animation-">
                                            <i aria-hidden="true" class="fas fa-phone-volume"></i> </span>
                                    </div>
                                    <div class="elementor-icon-box-content">
                                        <h4 class="elementor-icon-box-title">
                                            <span>
                                                Toll Free &amp; IVR Integration </span>
                                        </h4>
                                        <p class="elementor-icon-box-description">
                                            We offer integration of all major cloud telephony companies in India. </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <!-- 
                <div class="container">
                    <div class="row pt-45">
                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-448df8b elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="448df8b" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="fab fa-google"></i> </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Google Ads Integration </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Get Data of your Google ads in your dashboard from your Google
                                                        Ads Account. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="elementor-icon-box-wrapper">
                                    <div class="elementor-icon-box-icon">
                                        <span class="elementor-icon elementor-animation-">
                                            <i aria-hidden="true" class="fas fa-phone-volume"></i> </span>
                                    </div>
                                    <div class="elementor-icon-box-content">
                                        <h4 class="elementor-icon-box-title">
                                            <span>
                                                Toll Free &amp; IVR Integration </span>
                                        </h4>
                                        <p class="elementor-icon-box-description">
                                            We offer integration of all major cloud telephony companies in India. </p>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="courses-card">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-b0cfd79 elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="b0cfd79" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="fas fa-chart-pie"></i> </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Tracking &amp; progress </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Get Tracking of All Leads &amp; Enrolments to verify the
                                                        strength of Admissions . </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="courses-card">

                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-8431b6f elementor-view-stacked elementor-shape-square elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box"
                                        data-id="8431b6f" data-element_type="widget"
                                        data-widget_type="icon-box.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-icon-box-wrapper">
                                                <div class="elementor-icon-box-icon">
                                                    <span class="elementor-icon elementor-animation-">
                                                        <i aria-hidden="true" class="fab fa-facebook-square"></i>
                                                    </span>
                                                </div>
                                                <div class="elementor-icon-box-content">
                                                    <h4 class="elementor-icon-box-title">
                                                        <span>
                                                            Facebook Ads Integration </span>
                                                    </h4>
                                                    <p class="elementor-icon-box-description">
                                                        Get Data from your facebook ads directly from your Meta
                                                        Business Account or Facebook page. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!-- 77777 -->

<div class="agile-productive">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-md-center">
          <h3 class="fs26">Everything you need to <br class="d-none d-md-block"> make your teams agile and productive</h3>
        </div>
        <div class="col-sm-12 col-md-4 text-md-center">
          <div class="agile-productive-item have-line-effect">
            <div class="align-items-center d-md-block d-flex justify-content-start row">
              <div class="col col-auto col-md-12 mb-0 mb-md-1 pr-0">
                <img decoding="async" alt="Sales Team" data-src="" class="agile-productive-icon lazyloaded" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/sales-icon.png" loading="lazy"><noscript><img decoding="async" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/sales-icon.png" alt="Sales Team" class="agile-productive-icon"></noscript>
              </div>
              <div class="col col-md-12">
                <h4 class="mb-0">Sales Teams</h4>
              </div>
            </div>
            <p class="mt-md-0 mt-2"> Drive leads to closure and at scale with powerful sales management, performance reporting, and
              notifications.
            </p>
            <button type="button" class="btn btn-info"><a href="#" class="btn btn-action-btn btn-sm px-3">Learn More</a></button>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 text-md-center">
          <div class="agile-productive-item have-line-effect">
            <div class="align-items-center d-md-block d-flex justify-content-start row">
              <div class="col col-auto col-md-12 mb-0 mb-md-1 pr-0">
                <img decoding="async" alt="Marketing Team" data-src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/marketig-icon.png" class="agile-productive-icon lazyloaded" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/marketig-icon.png" loading="lazy"><noscript><img decoding="async" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/marketig-icon.png" alt="Marketing Team" class="agile-productive-icon"></noscript>
              </div>
              <div class="col col col-md-12">
                <h4 class="mb-0">Marketing Teams</h4>
              </div>
            </div>
            <p class="mt-md-0 mt-2"> Attract right audiences, run complete inbound marketing campaigns at scale and contextually
              nurture
              leads down the sales funnel.</p>
            <button type="button" class="btn btn-info"><a href="#" class="btn btn-action-btn btn-sm px-3">Learn More</a></button>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 text-md-center">
          <div class="agile-productive-item">
            <div class="align-items-center d-md-block d-flex justify-content-start row">
              <div class="col col-auto col-md-12 mb-0 mb-md-1 pr-0">
                <img decoding="async" alt="Admission Team" data-src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/admission-team.png" class="agile-productive-icon lazyloaded" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/admission-team.png" loading="lazy"><noscript><img decoding="async" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/admission-team.png" alt="Admission Team" class="agile-productive-icon"></noscript>
              </div>
              <div class="col col col-md-12">
                <h4 class="mb-0">Admissions Teams</h4>
              </div>
            </div>
            <p class="mt-md-0 mt-2">Streamline and manage applications at scale as you automate all application and
              post-application
              tasks in a single platform.
            </p>
            <button type="button" class="btn btn-info"><a href="#" class="btn btn-action-btn btn-sm px-3">Learn More</a></button>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 text-md-center">
          <div class="agile-productive-item have-line-effect">
            <div class="align-items-center d-md-block d-flex justify-content-start row">
              <div class="col col-auto col-md-12 mb-0 mb-md-1 pr-0">
                <img decoding="async" alt="Finance Teams" data-src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/finance-team.png" class="agile-productive-icon lazyloaded" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/finance-team.png" loading="lazy"><noscript><img decoding="async" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/finance-team.png" alt="Finance Teams" class="agile-productive-icon"></noscript>
              </div>
              <div class="col col col-md-12">
                <h4 class="mb-0">Finance Teams</h4>
              </div>
            </div>
            <p class="mt-md-0 mt-2">Make fee collection easier, faster, and more secure with one-click payment automations, fee
              forecasting, finance dashboards, and run-time settlement reports.</p>
            <button type="button" class="btn btn-info"><a href="#" class="btn btn-action-btn btn-sm px-3">Learn More</a></button>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 text-md-center">
          <div class="agile-productive-item">
            <div class="align-items-center d-md-block d-flex justify-content-start row">
              <div class="col col-auto col-md-12 mb-0 mb-md-1 pr-0">
                <img decoding="async" alt="Operation Teams" data-src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/opration-team.png" class="agile-productive-icon lazyloaded" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/opration-team.png" loading="lazy"><noscript><img decoding="async" src="https://dzcu0rfa3nwu9.cloudfront.net/wp-content/uploads/2023/01/opration-team.png" alt="Operation Teams" class="agile-productive-icon"></noscript>
              </div>
              <div class="col col col-md-12">
                <h4 class="mb-0">Operation Teams</h4>
              </div>
            </div>
            <p class="mt-md-0 mt-2"> Streamline processes and make migration easy as you gain control of everything from data to
              technology.</p>
            <button type="button" class="btn btn-info"><a href="#" class="btn btn-action-btn btn-sm px-3">Learn More</a></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
<!-- 8 -->
<!-- <div class="courses-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Our Courses</span>
            <h2 class="h2-color2">Integrations We Offer</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="../../../assets/img/logo/DASHBOARD 170 X140.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">Free</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor1.png" alt="Images">
                        <h3 class="name"><a routerLink="/">David McLean</a> </h3>
                    </div>
                    <h3><a routerLink="/">Introduction to Linear Models and Matrix Algebra</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 8 Weeks Long</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img2.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">$80</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor2.png" alt="Images">
                        <h3 class="name"><a routerLink="/">Jems ward</a></h3>
                    </div>
                    <h3><a routerLink="/"> Introduction to Quantitative Methods</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 9 Weeks Long</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img3.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">$78</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor3.png" alt="Images">
                        <h3 class="name"><a routerLink="/">Juhan Luis</a></h3>
                    </div>
                    <h3><a routerLink="/">Data Science Inference and Modeling</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 5 Weeks Long</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card">
                    <div class="images">
                        <a routerLink="/"><img src="assets/img/courses/courses-img4.png" alt="Images"></a>
                        <div class="rate"> <a routerLink="/">Free</a></div>
                    </div>
                    <div class="instructor">
                        <img src="assets/img/courses/courses-instructor4.png" alt="Images">
                        <h3 class="name"><a routerLink="/"> Miron Don</a></h3>
                    </div>
                    <h3><a routerLink="/">Introduction to Linear Data Visualization</a></h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class="flaticon-phone"></i> Available Now</li>
                        <li><i class="flaticon-clock"></i> 4 Weeks Long</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- 9 -->
<!-- <div class="testimonial-area-two pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="testimonial-content">
                    <div class="testimonial-content1">
                        <div class="testimonial-text">
                            <h3>10 +</h3>
                            <span>ON THE MARKET</span>
                        </div>
                    </div>

                    <div class="testimonial-content2">
                        <div class="testimonial-text">
                            <h3>10,000+</h3>
                            <span>LEARNERS</span>
                        </div>
                    </div>

                    <div class="testimonial-content3">
                        <div class="testimonial-text">
                            <h3>196+</h3>
                            <span>COUNTRIES</span>
                        </div>
                    </div>

                    <div class="testimonial-content4">
                        <div class="testimonial-text">
                            <h3>125+</h3>
                            <span>COURSE</span>
                        </div>
                    </div>

                    <div class="testimonial-shape1">
                        <img src="assets/img/shape/shape15.png" alt="Images">
                    </div>

                    <div class="testimonial-shape2">
                        <img src="assets/img/shape/shape16.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="testimonial-slider-area">
                    <div class="testimonial-title-2">
                        <h2>Words From Customers</h2>
                    </div>

                    <div class="testimonial-slider-two owl-carousel owl-theme">
                        <div class="testimonial-item-2">
                            <p>I chose Zinka because of their value Andincredible superior customer Service they really
                                awesome treated me like family.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                                <div class="content-title">
                                    <h3>David McLean</h3>
                                    <span>CEO & Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>I chose Zinka because of their value Andincredible superior customer Service they really
                                awesome treated me like family.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                                <div class="content-title">
                                    <h3>Jermin Jekson</h3>
                                    <span>Marketing Manager</span>
                                </div>
                            </div>
                        </div>

                        <div class="testimonial-item-2">
                            <p>I chose Zinka because of their value Andincredible superior customer Service they really
                                awesome treated me like family.</p>
                            <div class="content">
                                <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                                <div class="content-title">
                                    <h3>Julfiker Jeain</h3>
                                    <span>CEO At Ostino</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- 10 -->
<!-- <div class="blog-area blog-bg2 pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-title">
                    <div class="section-title">
                        <span class="sp-after">Our Blogs</span>
                        <h2 class="h2-color-2">Latest Valuable Insights</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua enim ad minim</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="blog-btn">
                    <a routerLink="/blog" class="default-btn-two">See all news <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog4.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details" class="title-color">The Home of Technology is in Front of
                                You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog5.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details" class="title-color">SEO Best Practices Mobile Friendliness</a>
                        </h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog6.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details" class="title-color">15 SEO Practices Website Architecture</a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- 11 -->
<!-- <div class="project-area">
    <div class="container">
        <div class="project-content">
            <h2>Already Interested! Do You Have Any Project to Working With?</h2>
            <a href="https://wa.aisensy.com/mBmyV5" class="project-btn">Get started <i class='bx bx-plus'></i></a>
        </div>
    </div>

    <div class="project-shape">
        <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
        <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
    </div>
</div> -->


<div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="">Team</span>
            <h2 class="h2-color2">Integration</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="../../../assets/img/1-removebg-preview.png" alt="Images" class="">
                <!-- <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>

            <div class="team-card team-rotated">
                <img src="../../../assets/img/2-removebg-preview.png" alt="Images">
                <!-- <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>

            <div class="team-card">
                <img src="../../../assets/img/3-removebg-preview.png" alt="Images">
                <!-- <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>

            <div class="team-card team-rotated-2">
                <img src="../../../assets/img/4-removebg-preview.png" alt="Images">\
                <!-- <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>

            <div class="team-card active">
                <img src="../../../assets/img/5-removebg-preview.png" alt="Images">
                <!-- <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>

            <div class="team-card team-rotated-2">
                <img src="../../../assets/img/6-removebg-preview.png" alt="Images">
                <!-- <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>
            <div class="team-card team-rotated-2">
                <img src="../../../assets/img/7-removebg-preview.png" alt="Images">
                <!-- <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>
            <div class="team-card team-rotated-2">
                <img src="../../../assets/img/6-removebg-preview.png" alt="Images">
                <!-- <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div> -->
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<br>
<!-- 12 -->
<div class="section-title aa33">
    <span class="">OUR  CLIENTS</span>
    </div>
    <div class="brand-logo-area">
        
        <div class="container-fluid">
            <div class="container-max">
                <div class="brand-logo-slider owl-carousel owl-theme">
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/1.png" alt="Images">
                    </div>
    
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/2.png" alt="Images">
                    </div>
    
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/icon10.png" alt="Images">
                    </div>
    
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/icon11.png" alt="Images">
                    </div>
    
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/icon12.png" alt="Images">
                    </div>
    
                    <div class="brand-logo-item">
                        <img src="../../../../assets/img/logo/New folder/blue color.png" alt="Images">
                    </div>
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/4.png" alt="Images">
                    </div>
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/5.png" alt="Images">
                    </div>
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/6.png" alt="Images">
                    </div>
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/7.png" alt="Images">
                    </div>
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/8.png" alt="Images">
                    </div>
                    <div class="brand-logo-item">
                        <img src="../../../assets/img/logo/New folder/9.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
<app-footer-style-two></app-footer-style-two>