<!-- <app-navbar-style-four></app-navbar-style-four> -->
<app-navbar-style-three></app-navbar-style-three>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center aat">
            <h3>Pricing Plan</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pricing Plan</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-50 pb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">Pricing Plan</span>
            <h2 class="h2-color">Price & Plans <b>Packages</b></h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">Prime
                            </h3>
                            <div class="price-rate">
                                <h2 class="color-1">₹ 25,000 </h2>
                                <span class="color-1">Per Academic Year</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i> Manage Leads upto <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>500</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>Web version</b> Business Whatsapp
                                </li>
                                <!-- <li><i class='bx bx-check'></i>IVR</li> -->
                                <!-- <li><i class='bx bx-check'></i>Website</li> -->
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <!-- <li><i class='bx bx-check'></i>Data Migrates</li> -->
                                <!-- <li><i class='bx bx-check'></i>Personal Sender Id</li> -->
                                <!-- <li><i class='bx bx-check'></i>SMS</li> -->
                                <li><i class='bx bx-check'></i> <b>10</b> No. of Users
                                </li>
                                <!-- <li><i class='bx bx-check'></i>Android mobile app</li> -->
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <li><del>IVR</del></li>
                                <li><del>Website</del></li>
                                <li><del>Data Migrates</del></li>
                                <li><del>Personal Sender Id</del></li>
                                <li><del>Android Mobile App</del></li>
                                
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a href="https://pages.razorpay.com/pl_EZeEqNXT0FenoF/view" target="_blank" class="purchase-btn button-bg1">Subscribe NOW</a>
                            <b>
                                <p>₹2083 per month</p>
                            </b>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">Prime Plus</h3>
                            <div class="price-rate">
                                <h2 class="color-2">₹60,000</h2>
                                <span class="color-2">Per Academic Year</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i> Manage Leads upto <b>1000</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>1000</b>
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>2500</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>API version</b> Business Whatsapp
                                </li>
                                <li><i class='bx bx-check'></i>IVR</li>
                                <li><i class='bx bx-check'></i>Website</li>
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <li><i class='bx bx-check'></i>Data Migrates</li>
                                <li><i class='bx bx-check'></i>Personal Sender Id</li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i> <b>20</b> No. of Users
                                </li>
                                <li><i class='bx bx-check'></i>Android mobile app</li>
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <!-- <li><i class='bx bx-check'></i>SMS</li> -->
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a href="https://pages.razorpay.com/pl_EZdeRDinEpFbbO/view" target="_blank" class="purchase-btn button-bg2">Subscribe Now</a>
                            <b>
                                <p>₹5000 per month</p>
                            </b>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">Prime Pro</h3>
                            <div class="price-rate">
                                <h2 class="color-3">₹1,00,000</h2>
                                <span class="color-3">Per Academic Year</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Landing Page
                                </li>
                                <li><i class='bx bx-check'></i> Live Application Form
                                </li>
                                <li><i class='bx bx-check'></i>  Leads up to <b>Unlimited</b>
                                    
                                </li>
                                <li><i class='bx bx-check'></i>  Students up to <b>Unlimited</b>
                                    
                                </li>
                                <li><i class='bx bx-check'></i>  Applications up to <b>Unlimited</b>
                                </li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i>Transaction Email</li>
                                <li><i class='bx bx-check'></i> <b>API version</b> Business Whatsapp
                                </li>
                                <li><i class='bx bx-check'></i>IVR</li>
                                <li><i class='bx bx-check'></i>Website</li>
                                <li><i class='bx bx-check'></i>Payment Gateway</li>
                                <li><i class='bx bx-check'></i> Employee Portal
                                </li>
                                <li><i class='bx bx-check'></i>Data Migrates</li>
                                <li><i class='bx bx-check'></i>Personal Sender Id</li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><i class='bx bx-check'></i> <b>20</b> No. of Users
                                </li>
                                <li><i class='bx bx-check'></i>Android mobile app</li>
                                <li><i class='bx bx-check'></i>Facebook Integration</li>
                                <li><del>Student Portal</del></li>
                                <li><i class='bx bx-check'></i>SMS</li>
                                <li><del>Multi Campus Management</del></li>
                            </ul>
                            <a href="https://pages.razorpay.com/pl_EZefxZp7tG41xL/view" target="_blank" class="purchase-btn button-bg3">Subscribe Now</a>
                            <b>
                                <p>₹8,333.3 per month</p>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">ROOF PARTY POLAROID</h2>
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">Master Cleanse Reliac Heirloom</h1>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/3">
              <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">Shooting Stars</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>
                  <a class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                      <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">The Catalyzer</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>
                  <a class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="p-4 md:w-1/3">
              <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div class="flex items-center mb-3">
                  <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                      <circle cx="6" cy="6" r="3"></circle>
                      <circle cx="6" cy="18" r="3"></circle>
                      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                    </svg>
                  </div>
                  <h2 class="text-gray-900 text-lg title-font font-medium">Neptune</h2>
                </div>
                <div class="flex-grow">
                  <p class="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>
                  <a class="mt-3 text-indigo-500 inline-flex items-center">Learn More
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->



    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>



<div class="zfree-contact-float">
    <div class="zfree-plan-new">
        <div class="zfeatures-icon-new">
            <div>&nbsp;</div>
        </div>
        <div class="zfeatures-container-new">
            <h4>Forever Free Plan</h4>
            <ul>
                <li>Up to five users, 5GB/User, 25MB attachment limit.</li>
                <li>Web access and free mobile apps*.</li>
                <li>Email hosting for single domain.</li>
            </ul>
        </div>
        <div class="zfeatures-btn-new"><a class="zcta-new zcta-free-new try-plan-new"
                href="https://mail.zoho.in/signup?type=org&amp;plan=free">TRY NOW</a><a
                class="zcta-new zcta-free-new sign-up" href="https://mail.zoho.in/signup?type=org&amp;plan=free">Sign Up
                Now</a></div>
    </div>
    <div class="partner-plan">
        <div class="zfeatures-icon-new">
            <div>&nbsp;</div>
        </div>
        <div class="zfeatures-container-new" style="height: 152.198px;">
            <h4>Contact Sales</h4>
            <p>Get custom plans for bulk users and enterprises.</p>
        </div>
        <div class="zfeatures-btn-new"><a class="zcta-new getquote-btn" href="javascript:;">CONTACT US</a></div>
    </div>
    <div class="flexi-pricing">
        <div class="zfeatures-icon-new">
            <div>&nbsp;</div>
        </div>
        <div class="zfeatures-container-new" style="height: 152.198px;">
            <h4>Flexible pricing</h4>
            <p>Mix and match different plans for different users in your organization.</p>
        </div>
        <div class="zfeatures-btn-new"><a class="zcta-new"
                href="/workplace/flexible-pricing.html?src=mailpricing">Explore now</a></div>
    </div>
</div>


<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>