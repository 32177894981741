import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { AiStartupComponent } from './components/pages/ai-startup/ai-startup.component';
// import { MachineLearningComponent } from './components/pages/machine-learning/machine-learning.component';
// import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { CaseStudyDetailsComponent } from './components/pages/case-study-details/case-study-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
// import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
// import { ContactComponent } from './components/pages/contact/contact.component';
import { MainpageComponent } from './components/mainpage/mainpage.component';
import { DetailContactComponent } from './components/multiplePage/detail-contact/detail-contact.component';
import { EdAboutComponent } from './components/multiplePage/ed-about/ed-about.component';
import { EdPriceComponent } from './components/multiplePage/ed-price/ed-price.component';
import { EdFaqComponent } from './components/multiplePage/ed-faq/ed-faq.component';
import { EdTermConditionsComponent } from './components/multiplePage/ed-term-conditions/ed-term-conditions.component';
import { EdLeadManagementComponent } from './components/multiplePage/ed-lead-management/ed-lead-management.component';
import { DemoPageComponent } from './components/multiplePage/demo-page/demo-page.component';
import { LearnMoreComponent } from './components/multiplePage/learn-more/learn-more.component';
import { EdFeatureComponent } from './components/multiplePage/ed-feature/ed-feature.component';

const routes: Routes = [


    {path:'',component:MainpageComponent},



    {path: '#####', component: SeoAgencyComponent},
    {path: 'ai-startup', component: AiStartupComponent},
    // {path: '', component: MachineLearningComponent},
    {path:'about',component:EdAboutComponent},
    // {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    // {path: 'pricing', component: PricingComponent},
    {path:'pricing',component:EdPriceComponent},
    // {path: 'faq', component: FaqComponent},
    {path:'faq',component:EdFaqComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'case-study', component: CaseStudyComponent},
    {path: 'case-study-details', component: CaseStudyDetailsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'sign-in', component: SignInComponent},
    // {path: 'sign-up', component: SignUpComponent},
    // {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'terms-conditions', component: EdTermConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path:'contact',component:DetailContactComponent},
    {path:'lead',component:EdLeadManagementComponent},
    {path:'demo',component:DemoPageComponent},
    {path:'learn',component:LearnMoreComponent},
    {path:'features',component:EdFeatureComponent},
    // {path:'www.eduwego.in/contact',component:DetailContactComponent},
    // {path: 'contact', component: ContactComponent},
    

    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }