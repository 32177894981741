import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ed-faq',
  templateUrl: './ed-faq.component.html',
  styleUrls: ['./ed-faq.component.scss']
})
export class EdFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
