<!-- <p>ed-tech-banner works!</p> -->
<div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon10.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon11.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon12.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="../../../../assets/img/logo/New folder/blue color.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/4.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/5.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/6.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/7.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/8.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/9.png" alt="Images">
                </div>
                <!-- <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon20.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon21.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon2.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon3.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon4.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon5.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon7.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon8.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="../../../assets/img/logo/New folder/icon9.png" alt="Images">
                </div> -->
            </div>
        </div>
    </div>
</div>