import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ed-about',
  templateUrl: './ed-about.component.html',
  styleUrls: ['./ed-about.component.scss']
})
export class EdAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  
  }

  link()
  {
    window.open("https://www.youtube.com/embed/O8BLuLUsQmo?vq=hd1080&autoplay=1&modestbranding=1&rel=0")
  }
  videoVisible = false;

  showVideo() {
    this.videoVisible = true;
  }

}
