<!-- <app-navbar-style-four></app-navbar-style-four> -->
<app-navbar-style-three></app-navbar-style-three>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/service-details.jpg" alt="Images">
                        <h2>SOLUTIONS BY EDUWEGO</h2>
                        <p>Our Cloud Based STUDENT RELATIONs
                            MANAGEMENT System will help you to resolve
                            all of these burning issues. Digitize your
                            admission office to grow number of admissions.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Leads Management</h3>
                                <p>From Landing page and social media
                                    platforms. Lead Allocation to Nurturing.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Associates Management </h3>
                                <p>Associates can upload candidate Details
                                    Make Fee Payment | Admission Letters</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Admissions Management</h3>
                                <p>Generate offer Letters | Application Fee
                                    Management | Admission & all other
                                    Letters</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Live Application Management</h3>
                                <p>We will design your Application Form
                                    live as your paper print applications.
                                    </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Employee Management</h3>
                                <p>Attendance Management | Leads
                                    Management | Teachers | Staffs | Counsellors
                                    </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Placement Management</h3>
                                <p>Students can check job openings | Apply
                                    Directly from Students Portal.
                                    </p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>WHY EDUWEGO</h2>
                                    <p>Its 100 % Safe & Secure SaaS to save you to lose any opportunities to
                                        grow your admissions. We will we giving end to end solution to grow
                                        the numbers of admissions by 60 X. This is fully designed inculcating
                                        feedbacks & suggestions by the top Admissions Experts. This
                                        empowers the Admission Team not to lose even a single lead from
                                        different channels.</p>
                                    <!-- <ul>
                                        <li><i class='bx bx-check'></i> </li>
                                        <li><i class='bx bx-check'></i> </li>
                                        <li><i class='bx bx-check'></i> </li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore tus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis onsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->

                    <div class="services-widget-list">
                        <ul>
                            <li class="active">
                                <span>95%</span>
                                <div class="content list1">
                                    <h3> (SRM)</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Live Application Form</h3>
                                </div>
                                <span>66%</span>
                            </li>
                            <li>
                                <span>100%</span>
                                <div class="content list3">
                                    <h3>Safe & Secure SaaS</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Website</h3>
                                </div>
                                <span>75%</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="widget-category">
                        <h3>Other Services</h3>

                        <ul>
                            <li><a routerLink="/lead">Lead Management</a></li>
                            <li><a routerLink="/services-details">Lead Nurturing</a></li>
                            <li><a routerLink="/services-details">Associates Management</a></li>
                            <li><a routerLink="/services-details">Live Application Management</a></li>
                            <li><a routerLink="/services-details">Admissions Management</a></li>
                            <li><a routerLink="/lead">Assignments Management</a></li>
                            <li><a routerLink="/services-details">Placement Management</a></li>
                            <li><a routerLink="/services-details">Counsellors Management</a></li>
                            <li><a routerLink="/services-details">Study Materials Management</a></li>
                            <li><a routerLink="/services-details">Batch / Course Management</a></li>
                        </ul>
                    </div>

                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel: +91931852 9318"> +91-9318 52 9318</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:hello@eduwego.in">hello@eduwego.in</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Location:</h3>
                                    <span>#5 , Electronic City Phase 1 , Neeladari Road , <br>Bangalore, 560100</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="/services-details">PDF Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="/services-details">Services Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-ed-tech-banner></app-ed-tech-banner>

<app-footer-style-two></app-footer-style-two>
