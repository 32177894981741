<!-- <!doctype html>
<html lang="zxx">

<head>
    <meta charset="utf-8">
    <title>Eduwego</title>
    <base href="/">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
    <link rel="icon" type="image/x-icon" href="assets/img/logo/favicon.png">
    <link
        href="https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap"
        rel="stylesheet">
    <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet">
    <script src="https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp"></script>

</head>

<body> -->
   
    <footer class="footer-area footer-bg2 pt-100">
        <div class="container">
            <div class="footer-midal pb-1">
                <div class="row">
                    <div class="col-lg-3 col-sm-3">
                        <div class="footer-widget">
                            <div class="footer-img">
                                <img src="../../../../assets/img/logo/CRM-Redefined-e1677831372518-removebg-preview.png" style="width: 50%;" alt="Images">
                            </div>
                            <p>Try us to believe us !
                            </p>
                            <div class="footer-social-icon">
                                <ul class="social-link">
                                    <!-- <li><a href="https://www.facebook.com/eduwego/" target="_blank"><i class='bx bxl-facebook'></i></a></li>  -->
                                    <!-- <li><a href="https://twitter.com/eduwego" target="_blank"><i class='bx bxl-twitter'></i></a></li>  -->
                                    <!-- <li><a href="https://www.instagram.com/eduwego.in/" target="_blank"><i class='bx bxl-instagram'></i></a></li>  -->
                                    <!-- <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>  -->
                                    <!-- <li><a href="https://www.youtube.com/@eduwego" target="_blank"><i class='bx bxl-youtube'></i></a></li>  -->
                                    <a href="https://www.facebook.com/eduwego/"><img src="../../../../assets/img/logo/feature/icons8-facebook-35.png" alt=""></a>
                                    <a href="https://twitter.com/eduwego"><img src="../../../../assets/img/logo/feature/icons8-twitter-35 1.png" alt=""></a>
                                    <a href="https://www.instagram.com/eduwego.in/"><img src="../../../../assets/img/logo/feature/icons8-insta-35.png" alt=""></a>
                                    <a href="https://www.youtube.com/@eduwego"><img src="../../../../assets/img/logo/feature/icons8-youtube-35.png" alt=""></a>

                                </ul>
                            </div>
                            <br>
                            <div class="footer-img">
                                <b><p>Download The App Now</p></b>
                                <img src="../../../../assets/img/icons8-android-os-35.png"alt="Images">
                                <img src="../../../../assets/img/icons8-google-play-35.png"alt="Images">
                                
                            </div>
                            <div class="footer-img">
                                <!-- <img src="../../../../assets/img/icons8-google-play-35.png" salt="Images"> -->
                            </div>
                        </div>
                    </div>
    
                    <div class="col-lg-2 col-sm-5">
                        <div class="footer-widget">
                            <h3>Products</h3>
    
                            <ul class="footer-list">
                                <li><a routerLink="/lead">Lead management</a></li>
                                <li><a routerLink="/">Students management </a></li>
                                <li><a routerLink="/">App management</a></li>
                                <li><a routerLink="/">Fees management</a></li>
                                <li><a routerLink="/">Whatsapp BOT</a></li>
                                <li><a routerLink="/">Education CRM</a></li>
                                
                                <!-- <li><a routerLink="/">Admission</a></li>
                                <li><a routerLink="/">Admission</a></li> -->
                            </ul>
                        </div>
                    </div>
                    
    
      
                    <div class="col-lg-3 col-sm-4">
                        <div class="footer-widget">
                            <h3>Industries </h3>
    
                            <ul class="footer-list">
                                <li><a routerLink="/">EdTech</a></li>
                                <li><a routerLink="/">Coaching & Training Institutes</a></li>
                                <li><a routerLink="/">K-12 Schools</a></li>
                                <li><a routerLink="/">Preschool & playschools</a></li>
                                <li><a routerLink="/">Online Degree programmes</a></li>
                                <li><a routerLink="/">Higher Education</a></li>
                                <li><a routerLink="/">Study Abroad Consultants</a></li>
                                
                                
                                <!-- <li><a routerLink="/">Admission</a></li>
                                <li><a routerLink="/">Admission</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-5">
                        <div class="footer-widget">
                            <h3>Resources</h3>
    
                            <ul class="footer-list">
                                <li><a routerLink="/">100 % Secured</a></li>
                                <li><a routerLink="/">Daily Backup </a></li>
                                <li><a routerLink="/">Indian Server</a></li>
                                <li><a routerLink="/">Optimized Server</a></li>
                                <li><a routerLink="/">Data Privacy</a></li>
                                <li><a routerLink="/">Fully Customized</a></li>
                                
                                <!-- <li><a routerLink="/">Admission</a></li>
                                <li><a routerLink="/">Admission</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-sm-5">
                        <div class="footer-widget">
                            <h3>Contact Us</h3>
    
                            <ul class="footer-list-two">
                                <li><i class='flaticon-telephone'></i><a href="tel:+91 9031022882">+91 9031022882</a></li>
                                <li><i class='flaticon-email-1'></i><a href="mailto:hello@eduwego.in">hello@eduwego.in</a></li>
                                <li><i class='flaticon-place'></i>#5, 10th Cross , Neeladari Nagar , Electronic City Phase 1 , Bangalore , Karnataka.</li>
                                
                                <!-- <li><a routerLink="/">Admission</a></li>
                                <li><a routerLink="/">Admission</a></li> -->
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="col-lg-3 col-sm-7">
                        <div class="footer-widget pl-5">
                            <h3>Resources</h3>
    
                            <ul class="footer-list">
                                <li><a routerLink="/">Home</a></li>
                                <li><a routerLink="/about">About</a></li>
                                <li><a routerLink="/case-study">Case Studies</a></li>
                                <li><a routerLink="/blog">Our Blog</a></li>
                                <li><a routerLink="/contact">Contact</a></li>
                           
                             
    
                            </ul>
                        </div>
                    </div> -->
    
                    <!-- <div class="col-lg-3 col-sm-5">
                        <div class="footer-widget">
                            <h3>Address</h3>
    
                            <ul class="footer-list-two">
                                <li><i class='flaticon-telephone'></i><a href="tel:+91 9031022882">+91 9031022882</a></li>
                                <li><i class='flaticon-email-1'></i><a href="mailto:hello@eduwego.in">hello@eduwego.in</a></li>
                                <li><i class='flaticon-place'></i>#5, 10th Cross , Neeladari Nagar , Electronic City Phase 1 , Bangalore , Karnataka.</li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
    
           
        </div>
    
    
        <div class="container ml-18">
            <div class="col-lg-12">

                <h4>Eduwego for higher education</h4>
                <p class="footer-all-courses-list">
                <a href="">Admission CRM</a> |
                <a href="">Enrollment CRM</a> |
                <a href=""> Lead Management</a> |
                <a href="">Education Counsellor</a> |
                <a href="">Admission Coversion</a> |
                <a href="">Application Management</a> |
                <a href="">Student Management</a> |
                <a href="">Placement Management</a> |
                <a href="">Associate Management</a> |
                <!-- <a href=""></a> |
                <a href=""></a> |
                <a href=""></a> |
                <a href=""></a> | -->
                <a href=""> Payments to Associates</a> |
                <a href="">Associate Portal</a> |
                <a href="">Multi Campus Management</a> |
                <a href="">Staff Management</a> |
                <a href=""> Whatsapp to Stud</a> |
                <a href="">Email to Stud</a> |
                <a href="">Email Communication</a> |
                <a href=""> Fee Managemnt</a> |
                <a href=""> Admission Counselling</a> |
                <a href=""> Generate Admission Letter Automatic</a> |
                <a href="">Bona fide Letter</a> |
                <a href="">Bona fide With Fee Structure</a> |
                <a href="">Hostel Certificate</a> |
                <a href="">Loan Letter</a> |
                <a href="">Demand Letter</a> |
                <a href="">Direct Admission</a> |
                <a href="">Affordable CRM</a> |
                <a href="">No Paper Form Alternate</a> |
                <a href="">Extra Edge alternate</a> |
                <a href="">Lead & Squad</a> |
             
                </p>
                <h4>Eduwego for Training  & Coaching Institutes</h4>
                <p class="footer-all-courses-list">
                <a href="">Batch Management</a> |
                <a href="">Assignment Management</a> |
                <a href="">LMS</a> |
                <a href="">Coaching App</a> |
                <a href="">Multi Branch Management</a> |
                <a href="">Facebook Integration</a> |
                <a href="">Google Integration</a> |
                <a href="">Google my Business Integration</a> |
                <a href=""> Offer Letter Generation</a> |
                <!-- <a href=""></a> | -->
                
                </p>
                <h4>Eduwego For Schools</h4>
                <p class="footer-all-courses-list">
                    <a href="">Batch Management</a> |
                    <a href="">Assignment Management</a> |
                    <a href="">LMS</a> |
                    <a href="">Coaching App</a> |
                    <a href="">Multi Branch Management</a> |
                    <a href="">Facebook Integration</a> |
                    <a href="">Google Integration</a> |
                    <a href="">Google my Business Integration</a> |
                    <a href=""> Offer Letter Generation</a> |
                </p>
                <!-- <h4>Degree program</h4>
                <p class="footer-all-courses-list">
                <a href="artificial-intelligence-iit-jammu/">Online M Tech in AI &amp; ML</a> |
                <a href="science-online/">Masters degree in Data Science</a> |
                <a href="ent/">MBA Big Data Management</a> |
                <a href="ntelligence-degree-online/">Masters degree in Artificial Intelligence</a> |
                <a href="nline-course/">Global MBA</a> |
                <a href="rketing/">MBA in International Marketing</a> |
                <a href="ence-iu-two-years/">Masters in Computer Science</a> |
                <a href="ccounting-online/">MBA in Finance and Accounting</a> |
                <a href="management/">Masters in Engineering Management</a> |
                <a href="versity-of-essex/">Msc in Data Science</a> |
                </p> -->
                <!-- <p class="copyright-text mb-0">
                © Copyright 2011 - 2023 Intellipaat Software Solutions Pvt. Ltd.
                </p> -->
                <div class="copy-right-area">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="copy-right-text text-start">
                                <p>© Copyright 2023 Eduwego is Proudly Owned by <a href="https://www.eduwego.in/" target="_blank">Zeqoon</a></p>
                                <p>ISO 9001:2015 | ISO/IEC 27001:2013 CERTIFIED</p>
                            </div>
                        </div>
        
                        <div class="col-lg-4">
                            <div class="copy-right-list">
                                <ul>
                                    <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                                    <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>
        
        
    </footer>
<!-- </body>

 <script src="https://cdn.tailwindcss.com"></script> 
 <script src="../path/to/flowbite/dist/flowbite.min.js"></script> 

</html> -->