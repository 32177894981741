<app-navbar-style-three></app-navbar-style-three>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Features</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Features</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Features</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a ><img src="../../../../assets/img/logo/feature/Lead Generation.png" alt="Images"></a>
                    <h3><a >Lead Generation
                    </a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a ><img src="../../../../assets/img/logo/feature/Live Application.png" alt="Images"></a>
                    <h3><a >Live Application Form</a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a ><img src="../../../../assets/img/logo/feature/Fees.png" alt="Images"></a>
                    <h3><a >Fee Payments</a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a ><img src="../../../../assets/img/logo/feature/Referrals.png" alt="Images"></a>
                    <h3><a >Referrals & Associate
                    </a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a ><img src="../../../../assets/img/logo/feature/Generate Admission.png" alt="Images"></a>
                    <h3><a >Assignments</a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a ><img src="../../../../assets/img/logo/feature/Results.png" alt="Images"></a>
                    <h3><a >Batch & Results
                    </a></h3>
                    <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                    
                </div>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Admission.png" alt="Images"></a>
                        <h3><a >Attendance</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Payment Gateway.png" alt="Images"></a>
                        <h3><a >Payment Gateway
                        </a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Placement.png" alt="Images"></a>
                        <h3><a >Placements/Internships</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Study Material.png" alt="Images"></a>
                        <h3><a >Study Materials</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Admission.png" alt="Images"></a>
                        <h3><a >Generate Admission</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Migration Our letter.png" alt="Images"></a>
                        <h3><a >Migration Our Letter</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Hostel.png" alt="Images"></a>
                        <h3><a >Hostel Certification</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Demand Letter.png" alt="Images"></a>
                        <h3><a >Demand Letter</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Migration Our letter.png" alt="Images"></a>
                        <h3><a >Migration Certification</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Whatsapp.png" alt="Images"></a>
                        <h3><a >Whatsapp Communication
                        </a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Lead Verification.png" alt="Images"></a>
                        <h3><a >Lead verification
                        </a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-card">
                        <a ><img src="../../../../assets/img/logo/feature/Call IVR.png" alt="Images"></a>
                        <h3><a >IVR & Call recording 
                        </a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p> -->
                        
                    </div>
                </div>
            
            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/services" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services" class="page-numbers">2</a>
                    <a routerLink="/services" class="page-numbers">3</a>
                    <a routerLink="/services" class="page-numbers">4</a>
                    <a routerLink="/services" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<app-ed-tech-banner></app-ed-tech-banner>

<app-footer-style-two></app-footer-style-two>